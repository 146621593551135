import React from "react"
import s from "./index.scss"
import "../utils/setRem"
import { palyGoogle, appStore, downloadApp } from "../utils"
import iBooming from "./images/img1.png"
import img1 from "./images/with-us.png"
import img2 from "./images/img2.png"
import img3 from "./images/img3.png"
import button from "./images/img4.png"
import img4 from "./images/Frame.png"
import img5 from "./assest/img5.png"
import img8 from "./images/img5.png"
import desc2 from "./images/img6.png"
import desc3 from "./images/img7.png"
import desc4 from "./images/img8.png"
import img6 from "./assest/img6.png"
import img7 from "./images/img7.png"
import Connect from "./assest/Connect.png"
import title1 from "./images/title1.png"
import title2 from "./images/title2.png"
import title3 from "./images/title3.png"
import title4 from "./images/title4.png"
import text11 from "./images/text11.png"
import text12 from "./images/text12.png"
import deviceframes from "./images/deviceframes.png"
import text21 from "./images/text21.png"
import text22 from "./images/text22.png"
import text23 from "./images/text23.png"
import withUs from "./images/with-us.png"
import text31 from "./images/text31.png"
import text32 from "./images/text32.png"
import text33 from "./images/text33.png"
import img31 from "./images/img31.png"
import text41 from "./images/text41.png"
import text42 from "./images/text42.png"
import text43 from "./images/text43.png"
import text44 from "./images/text44.png"
import footer1 from "./images/footer1.png"
import footer2 from "./images/footer2.png"
import footer3 from "./images/footer3.png"
import footer4 from "./images/footer4.png"
import footer5 from "./images/footer5.png"
import footer6 from "./images/footer6.png"
import ins from "../assest/instagram-fill.svg";
import facebook from "../assest/facebook.svg";
export default function APPWeb() {
  const renderDown = () => {
    return (
      <div className={s.downBox}>
        <img onClick={downloadApp} src={button} />
        <div className={s.buttonBox}>
          <span onClick={appStore} />
          <span onClick={palyGoogle} />
        </div>
      </div>
    )
  }
  return (
    <div className={s.content}>
      <div className={s.content_top}>
        <img className={s.iBooming} src={iBooming} />
        <img className={s.img2} src={img2} />
        <img className={s.img3} src={img3} />
      </div>
      {renderDown()}
      <img
        style={{ width: "5.08rem", marginTop: "0.64rem" }}
        className={s.img4}
        src={img4}
      />

      <img style={{ width: "4.56rem", marginTop: "0.97rem" }} src={title1} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text11} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text12} />
      <img style={{ width: "6.47rem", marginTop: "0.28rem" }} src={deviceframes} />

      <img style={{ width: "6.75rem", marginTop: "0.97rem" }} src={title2} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text21} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text22} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text23} />
      <img style={{ width: "6.47rem", marginTop: "0" }} src={withUs} />

      <img style={{ width: "6.9rem", marginTop: "0.97rem" }} src={title3} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text31} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text32} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text33} />
      <img style={{ width: "6.9rem", marginTop: "0.18rem" }} src={img31} />

      <img style={{ width: "6.9rem", marginTop: "0.97rem" }} src={title4} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text41} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text42} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text43} />
      <img style={{ width: "4.13rem", marginTop: "0.28rem" }} src={Connect} />

      <img style={{ width: "6.75rem", marginTop: "0.97rem" }} src={desc4} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={text44} />
      <img style={{ width: "6.75rem", marginTop: "0.28rem" }} src={img7} />
      {renderDown()}
      <img style={{ width: "5.16rem", marginTop: "0.9rem" }} src={img6} />
      <div className={s.footer}>
        <div style={{ paddingLeft: '0.2rem', marginTop: '0.36rem' }}>
          <img style={{ width: "3.43rem", marginBottom: "0.2rem", marginLeft: 0 }} src={footer6} />
          <img style={{ width: "3.01rem", marginTop: "0.1rem", marginBottom: "0.2rem", marginLeft: 0 }} src={footer1} />
          <img style={{ width: "4.08rem", marginTop: "0.08rem" }} src={footer2} />
        </div>
        <div style={{ width: '40%', marginTop: '0.36rem' }}>
          <div style={{ paddingRight: '0.18rem', textAlign: 'right', marginBottom: "0.12rem", }}>
            <img style={{ width: "1.73rem", margin: 0, display: 'inline-block' }} src={footer5} />
          </div>
          <a href="https://www.ibooming.net/tc">
            <img style={{ width: "1.27rem", marginBottom: "0.1rem" }} src={footer3} />
          </a>
          <a
            href="https://www.ibooming.net/privacy"
            style={{ marginLeft: "0.2rem" }}
          >
            <img style={{ width: "1.08rem", marginBottom: "0.1rem" }} src={footer4} />
          </a>
          <div style={{ paddingRight: '0.18rem', textAlign: 'right' }}>
            <a style={{ opacity: 0.6 }} href="https://www.facebook.com/iBoomingOfficial"><img className={s.icons1} src={facebook} /></a>
            <a style={{ opacity: 0.6 }} href="https://instagram.com/ibooming_official"><img className={s.icons} src={ins} /></a>
          </div>
        </div>
      </div>
    </div>
  )
}
