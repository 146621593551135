import React from "react"
import s from "./index.scss"
import logo from "./imgages/iBooming-logo.png"
import banner_left1 from "./imgages/title1.png"
import banner_left2 from "./imgages/title2.png"
import banner_right from "./imgages/img-desc.png"
import grayText from "../assest/gray-text.png"
import desc from "./imgages/img6.png"
import Phone from "./imgages/deviceframes.png"
import desc2 from "./imgages/desc2.png"
import Phone2 from "./imgages/phone2.png"
import desc3 from "./imgages/desc3.png"
import Phone3 from "./imgages/phone3.png"
import desc4 from "./imgages/desc4.png"
import Phone4 from "./imgages/phone4.png"
import downDesc from "./imgages/img5.png"
import Button from "./imgages/down.png"
import aboutUs from "./imgages/banner.png"
import ins from "../assest/instagram-fill.svg";
import facebook from "../assest/facebook.svg";
import { palyGoogle, appStore, downloadApp } from "../utils"
export default function Web() {
  return (
    <div className={s.bg}>
      <div className={s.warpContent}>
        <div className={s.bannerBox}>
          <div className={s.banner__left}>
            <img className={s.logo} src={logo} />
            <img className={s.banner_left1} src={banner_left1} />
            <img className={s.banner_left2} src={banner_left2} />
            <div className={s.buttonBox}>
              <span onClick={appStore} />
              <span onClick={palyGoogle} />
            </div>
          </div>
          <img className={s.banner__right} src={banner_right} />
        </div>

        <div className={s.whatibooming}>
          <img className={s.phone} src={Phone} />
          <img className={s.desc} src={desc} />
        </div>
        <div className={s.whatibooming}>
          <img className={s.desc2} src={desc2} />
          <img className={s.phone2} src={Phone2} />
        </div>
        <div className={s.whatibooming}>
          <img className={s.phone3} src={Phone3} />
          <img className={s.desc3} src={desc3} />
        </div>
        <div className={s.whatibooming}>
          <img className={s.desc4} src={desc4} />
          <img className={s.phone4} src={Phone4} />
        </div>

        <div className={s.downloadBox}>
          <img src={downDesc} className={s.downDesc} />
          <img src={Button} onClick={downloadApp} className={s.downbtn} />
        </div>
      </div>
      <div className={s.footerWarp}>
        <div className={s.footer}>
          <div className={`${s.footerBorder} ${s.footerFlex} ${s.bottom15}`}>
            <div>
              <div className={s.alignLeft}><label>TEL: </label><span> 0812-8338-5119</span></div>
              <div>
                <span><label>Gmail: </label>tikstarmediaindonesia@gmail.com</span>
                <span className={s.ml160}>Website develop by PT Tik Star Media</span>
              </div>
            </div>
            <div>
              <a target="_blank" href="https://www.ibooming.net/tc">
                Team of service
              </a>
              <a
                href="https://www.ibooming.net/privacy"
                target="_blank"
                style={{ marginLeft: "40px" }}
              >
                Privacy policy
              </a>
            </div>
          </div>
          <div className={s.footerFlex}>
            <div className={s.logoTitle}>iBooming</div>
            <div>
              Hangzhou Jingchu Information Technology Co., Ltd.
            </div>
            <div className={s.other}>
              <a href="https://www.facebook.com/iBoomingOfficial"><img className={s.icons1} src={facebook} /></a>
              <a href="https://instagram.com/ibooming_official"><img className={s.icons} src={ins} /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
