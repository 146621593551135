// import { parse } from "querystring-es3"
window.os = {}
export function uuid(len = 8) {
  const S = "qwertyuioopasdfghjklzxcvbnmQWERTYUIOOPASDFGHJKLZXCVBNM0123456789"
  const LEN = S.length - 1
  return " "
    .repeat(len)
    .split("")
    .map(() => S[Math.round(Math.random() * LEN)])
    .join("")
}

// export const getPageQuery = () => parse(window.location.href.split("?")[1])

export function obj2url(obj) {
  let url = "?"
  // eslint-disable-next-line no-restricted-syntax
  for (const k in obj) {
    if (obj[k] !== "" && obj[k] !== null && obj[k] !== undefined) {
      url += `${k}=${obj[k]}&`
    } else {
      url += `${k}=${""}&`
    }
  }
  return url.slice(0, url.length - 1)
}

export function getParams(param) {
  const url = window.location.href
  const res = {}
  if (url.includes("?")) {
    const str = url.split("?")[1]
    const arr = str.split("&")
    arr.forEach((item) => {
      const key = item.split("=")[0]
      const val = item.split("=")[1]
      res[key] = decodeURIComponent(val) // 解码
    })
  }
  return res[param]
}

//金额转换
export function formatNumber(number) {
  const num = Math.abs(parseFloat(number))
  let ret = String(num)
  const result = ret.replace(/\B(?=(?:\d{3})+(?!\d))/g, ".")
  if (number < 0) {
    return `-Rp ${result}`
  } else {
    return `Rp ${result}`
  }
}

export function palyGoogle() {
  window.location.href =
    "https://play.google.com/store/apps/details?id=com.booming.booming"
}

export function appStore() {
  window.location.href = "https://apps.apple.com/us/app/ibooming/id1615570620"
}

export function downloadApp() {
  if (window.os.ios || window.os.mac) {
    appStore()
  } else {
    palyGoogle()
  }
}
